.opt-out-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 87%;
    margin-left: 1%;
    margin-top: 1%;
  }
  
  .opt-out-keywords, .opt-out-response {
    width: 48%;
  }
  
  .opt-out-keywords h3, .opt-out-response h3 {
    font-size: 18px;
    font-weight: 400;
    margin-right: 30px;
  }
  
  .opt-out-keywords p, .opt-out-response p {
    font-size: 14px;
    color: #666;
    margin-bottom: 18px;
  }
  
  .input-field {
    width: 30%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .keyword-button {
    display: block;
    width: 30%;
    padding: 10px;
    background-color: #efefef;
    border: none;
    border-radius: 4px;
    margin-bottom: 10px;
    text-align: left;
    cursor: pointer;
  }
  
  .add-more {
    font-size: 14px;
    color: #0a74da;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 20px;
  }
  
  .save-settings {
    padding: 10px 20px;
    background-color: rgb(33, 28, 82);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .opt-out-response {
    position: relative;
  }
  
  .toggle-container {
    display: flex;
    align-items: center;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
    margin-left: 4%;
    margin-right: 3%;
  }

/* Media query for when the zoom is approximately 150% on Chrome */
@media (min-width: 500px) and (max-width: 1476px) /* screens that appear smaller due to zoom */
 {
        .toggle-container {
          display: contents;
        }
}
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #004d40;
  }
  
  input:checked + .slider:before {
    transform: translateX(14px);
  }
  
  .configure-button {
    padding: 10px 20px;
    background-color: rgb(33, 28, 82);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .whatsapp-message {
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
    background-color: #e6f4ea;
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 48%;
    margin-top: 10%;
    margin-left: 30%;
  }
  
  .whatsapp-icon {
    margin-right: 10px;
    font-size: 20px;
    color: #25d366;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 30%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.modal-content h2 {
    margin-top: 0;
    font-size: 18px;
    font-weight: 400;
}

.radio-group {
    margin: 20px 0;
}

.radio-group label {
    display: block;
    margin-bottom: 10px;
}

.template-select label {
    display: block;
    margin-bottom: 5px;
}

.template-select select {
    width: 30%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.save-button {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.save-button:hover {
    background-color: #0056b3;
}

.cancel-button {
    background-color: #ccc;
    color: #333;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button:hover {
    background-color: #999;
}
