.rdw-property-decorator-wrapper {
  background: #e5f2fc;
  border-radius: 4px;
  padding: 1px 5px;
  color: #2f5fc2;
  font-style: italic;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}
