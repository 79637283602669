
.App {
  position: relative; /* Ensure the drawer is positioned relative to this container */
  overflow: hidden; /* Hide the overflow to prevent the drawer from being visible outside the container */
  width: 65%; /* Ensure the App container takes full width */
  height: 100%; /* Ensure the App container takes full height */
  max-width: 600px; /* Adjust as needed */
  margin: 0 auto; /* Center the App container */
  }
  
  .open-btn {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .drawer {
    position: absolute;
    bottom: 0;
    margin-left: 50%;
    /* margin-bottom: 30px; */
    left: 0;
    /* padding-left: 37px; */
    width: 44%;
    height: inherit;
    overflow-y: hidden;
    transition: 0.5s;
    background-color: white;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    border-radius: 16px;
    bottom: -100%; /* Start off-screen */
    transform: translateX(-50%);
    transition: bottom 0.5s ease-in-out;
    width: 80%; /* Adjust as needed */
    height: 50%; /* Adjust as needed */
    background-color: white;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  }
  .drawer.open {
    bottom: 5px;
}
.dropdownDrawer{
  position: absolute;
    bottom: 0;
    margin-left: 50%;
    /* margin-bottom: 30px; */
    left: 0;
    /* padding-left: 37px; */
    width: 44%;
    height: inherit;
    overflow-y: hidden;
    transition: 0.5s;
    background-color: white;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    border-radius: 16px;
    bottom: -100%; /* Start off-screen */
    transform: translateX(-50%);
    transition: bottom 0.5s ease-in-out;
    width: 80%; /* Adjust as needed */
    height: 50%; /* Adjust as needed */
    background-color: white;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  
}
.dropdownDrawer.open {
  bottom: 5px;

  border-radius: 11px;

}
  .drawer-content {
    color: white;
    padding: 20px;
  }
  
  .close-btn {
    position: absolute;
    top: 18px;
    left: 14px;
    font-size: 20px;
    color: black;
    background: none;
    border: none;
    cursor: pointer;
  }
  .header{
    border-bottom: 1px solid lightgray;
    width: 100%;
    padding: 0px 0px 9px;
    height: 54px;
    min-height: 54px;
  }
  .dropdownHeader { 
    border-bottom: 1px solid lightgray;
    width: 100%;
    /* padding: 18px 0px 9px; */
    height: 30px;
  }
  .main-content {
    padding: 20px;
    border-top: 1px solid lightgray;
    top: 89%;
    position: absolute;
    bottom: -5px;
    width: 100%;
  }
  .main-content-body{
    text-align: justify;
    margin-left: 12px;
    margin-top: 12px;
    gap: 12px;
    display: grid;
    overflow: overlay;
    max-height: 80%;
    width: 97%;
    padding: 0px 12px 6px 3px;
  }
  .select-content{
    display: flex;
    justify-content: space-between;
  }
  .component-error{
    position: absolute;
    top: -11px;
    left: 26%;
    transform: translateX(-144%);
    background-color: white;
    padding: 0px 1px 0px;
    white-space: nowrap; /* Prevent the text from wrapping */
  }
  .custom-localization-provider {
    /* Your custom styles here */
    background-color: lightgray;
    padding: 16px;
    border-radius: 4px;
  }
@keyframes animation {
    from {
      bottom: -60px
    }
    to {
      bottom: 20px
    }
  }

/* DatePicker.css */

.date-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 20px; */
}

.slider-wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.slider-container {
  height: 150px;
  overflow: hidden;
}

.keen-slider {
  display: flex;
  flex-direction: column;
  width: 100px;
}

.keen-slider__slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s;
  font-size: 18px;
}

.keen-slider__slide:hover {
  transform: scale(1.1);
}

.keen-slider__slide:focus {
  outline: none;
}

.selected-date {
  margin-top: 20px;
}

.actions {
  display: flex;
  justify-content: end;
  width: 100%;
  /* margin-top: 20px; */
}

.cancel-button,
.ok-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.cancel-button {
  background-color: white;
  color: green;
}

.ok-button {
  background-color: white;
  color: green;
}










.flow-table-container {
  /* width: 100%; */
  /* padding: 20px; */
  box-sizing: border-box;
  overflow-y: auto;
}
.flow-table tbody {
  display: block;
  max-height: calc(92vh - 144px); /* Same as the container's max-height */
  overflow-y: auto;
} 
.flow-table tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* Ensures the rows fit within the table's width */
}

.flow-table tr a{
  cursor: pointer;
  color: rgb(69, 57, 160);
}


.flow-table {
  width: 100%;
  border-collapse: collapse;
}
.flow-table thead {
  border-bottom: 1px solid lightgray;
  color: #7E8392;
}
.flow-table th,
.flow-table td {
  /* border: 1px solid #ddd; */
  padding: 10px;
  text-align: left;
  width: 22%;
  font-size: 13px;
}

.flow-table tbody tr {
  border-bottom: 1px solid lightgray;
}
.flow-table th {
  /* background-color: #f2f2f2; */
  padding: 12px;
  color: black;
    font-size: 13px;
    font-weight: 500;
    /* border-right: 1px solid lightgray; */
}

.status .status-draft {
  display: inline-block;
  padding: 0px 8px;
  border-radius: 12px;
  background-color: rgba(255,246,220,1);
  color: rgba(175,80,0,1);
}

.status .status-deprecate {
  display: inline-block;
  padding: 0px 8px;
  border-radius: 12px;
  background-color: #edf6fa;
  color: rgba(28,43,51,1);
}

.status .status-publish {
  display: inline-block;
  padding: 0px 8px;
  border-radius: 12px;
  background-color: rgba(239,246,234,1);
  color: rgba(0,126,89,1);
}

.status .status-blocked {
  display: inline-block;
  padding: 0px 8px;
  border-radius: 12px;
  background-color: #efd3d3;
  color: red;
}

.status .status-throttled {
  display: inline-block;
  padding: 0px 8px;
  border-radius: 12px;
  background-color: #eaeafc;
  color: #1717ed;
}

.action-btn {
  background-color: white;
  border: none;
  font-size: 20px;
  cursor: pointer;
  background: white;

}

.action-btn:hover {
  background-color: white;
  background: white !important;
}


/* CSS for the last column */
.flow-table th:nth-last-child(2),
.flow-table th:nth-last-child(2) {
  border-right: none;

}

.flow-table th:last-child,
.flow-table th:last-child{
  text-align: end;
    padding-right: 100px;

}

.flow-table td:last-child,
.flow-table td:last-child{
  text-align: end;
    padding-right: 100px;

}

/* CSS for the last column */
.flow-table th:first-child,
.flow-table th:first-child {
  padding-left: 50px;
}

/* CSS for the last column */
.flow-table td:first-child,
.flow-table td:first-child {
  padding-left: 50px;
}







.business-verification-container {
  width: 43%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f7f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  padding: 70px 0;
    border: 3px solid lightgrey;
    text-align: center;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.business-verification-container h2{
  margin: 7px 0px;
  font-weight: 500;
}

.business-verification-box {
  /* background-color: white; */
  border-radius: 8px;
  padding: 20px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.verification-header {
  margin-bottom: 20px;
  font-weight: 500;
}

.icon-info-circle {
  font-size: 20px;
  color: #007bff;
}

.toggle-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.verification-content {
  margin-bottom: 20px;
}

.verification-content p {
  margin: 0 0 10px;
}

.get-started-button {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.get-started-button:hover {
  background-color: #0056b3;
}
