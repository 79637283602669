.public-DraftEditor-content > span[style~="font-weight: bold;"] > span {
  font-weight: bold !important;
}

strong > em {
  font-weight: 600;
}

.MuiButtonBase-root > .MuiStack-root,
.MuiButtonBase-root > .MuiStack-root > .MuiBox-root {
  cursor: pointer;
}
