.container {
  text-align: center;
}

.notificationProgress {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar {
  height: 4px;
  border-radius: 4px;
  transition: 0.4s linear;
  transition-property: width, background-color;
  margin-top: -4px;
  margin-left: 1px;
  margin-right: 1px;
}

.success-bar {
  border-radius: 6px;
  // background: linear-gradient(to bottom, rgb(13 197 100 / 60%) 0%, #18b864 0%);
  background-color: #F6FFF9;
  cursor: pointer;
  border: 1px solid #ffff;
  color: black;
  width: 280px;
  display: flex;
  padding: 12px;
  justify-content: space-around;
  align-items: flex-start;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  justify-content: flex-start;
  z-index:9999999;
}

.error-bar {
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #f4b0a1 !important;
  // background: linear-gradient(rgb(230 9 36 / 60%) 0%, rgb(221 17 17) 0%);
  background-color: #FFF5F3;
  cursor: pointer;
  color: black;
  width: 280px;
  display: flex;
  padding: 12px;
  justify-content: space-around;
  align-items: flex-start;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  justify-content: flex-start;
  z-index:9999999;

}

.progress-striped-success .progress-bar {
  background-color: #48C1B5;
  width: 100%;
  background-image:#48C1B5;
  animation: progressAnimationStrike 5.5s;
}

.progress-striped-error .progress-bar {
  background-color: #e74c3c;
  width: calc(100% - 2px);
  // background-image: linear-gradient(45deg, rgb(230 137 131) 25%, transparent 25%, transparent 50%, rgb(251 135 127) 50%, rgb(232 145 139) 75%, transparent 75%, transparent);
  background-image: #e74c3c;
  animation: progressAnimationStrike 5.0s;
  background-color: #f4b0a1;
  margin-top: -4px;
  border-radius: 0;
}

@keyframes progressAnimationStrike {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}
.SnackbarContainer-top{
  position: fixed;
  bottom: 95vh;
  left: 20px;
  justify-content: flex-end;
  align-items: flex-start !important;
}