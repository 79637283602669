* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  /* ===== Scrollbar CSS ===== */
  ::selection {
    background: rgb(54, 84, 227);
    color: white;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    border-radius: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ececec;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(181, 184, 191);
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
}
.nc-footer {
  display: none !important;
}
.nc-header-mark-as-read{
  display: none !important;
}
.mantine-Badge-root{
  display: none;
}
div {
  cursor: default;
}

strong {
  font-weight: 600 !important;
}

//some classes have random unique id as prefix kindly don't remove these blocks as it will only reflect that particular component rather than all components

//here only those components styling are over written over defaults which don't come under React's default app component i.e (PopUp, Model, Dialog, Popover) components of MUI appear outside root class so applying global defaults for that . if you want to target any specfic mui component try to declare style in commonElements file otherewise overwrite it with dyanmic classname prefixes that mui provide therefore it only reflect on your desired component rather global design

.css-uwcd5u.SnackbarContainer-top {
  top: 2px !important;
}
.css-1arijsr-MuiSnackbar-root {
  top: 6px !important;
}

.MuiInputBase-root.Mui-disabled {
  border: 2px solid transparent !important;
}

select[disabled] {
  appearance: none;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  text-indent: 0.01px;
  text-overflow: "";
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root,
.css-1mxz8qt-MuiPaper-root {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08) !important;
}

.MuiOutlinedInput-notchedOutline,
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
  outline: none !important;
  display: none !important;
}

fieldset {
  border: none !important;
  outline: none !important;
  display: none !important;
}

.css-16473ix-MuiInputBase-root-MuiInput-root {
  &:before {
    border-bottom: none !important;
  }
  &:after {
    border-bottom: none !important;
  }
}

.css-1yuoo4-MuiButtonBase-root-MuiIconButton-root {
  padding: 0 !important;
}
.css-1laqsz7-MuiInputAdornment-root {
  margin-left: 0 !important;
}

.input-date-picker .MuiInputBase-input {
  padding: 0 !important;
}

input {
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}

.table-text,
.MuiButtonBase-root {
  color: #7e8392;
}
.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiPaper-root-MuiCard-root {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08) !important;
}

.MuiPaper-root-MuiCard-root {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08) !important;
}

//css for aop loader don't remove this
.loader {
  width: 100px;
  height: 75px;
  margin: 0 auto;
  background: #5b73e8;
  position: relative;
  border-radius: 100%;
}
.loader:before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  border: 15px solid transparent;
  border-top: 25px solid #5b73e8;
  transform: rotate(45deg);
  top: 50px;
  left: -15px;
}

.loader:after {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 20px 0 #fff, -20px 0 #fff;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: #1c35b2;
    box-shadow: 20px 0 #1c35b2, -20px 0 #fff;
  }
  50% {
    background-color: #fff;
    box-shadow: 20px 0 #1c35b2, -20px 0 #1c35b2;
  }
  100% {
    background-color: #1c35b2;
    box-shadow: 20px 0 #fff, -20px 0 #1c35b2;
  }
}

.MuiTabs-root {
  min-height: 40px !important;
}

.MuiAccordion-root {
  box-shadow: none !important;
}

.grecaptcha-badge {
  // visibility: hidden;
  display: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0;
  filter: invert(58%) sepia(6%) saturate(734%) hue-rotate(188deg)
    brightness(88%) contrast(89%);
}

.MuiAccordion-root .MuiList-root .Mui-selected {
  border-radius: 4px !important;
}

//popup dialog max-width changed from 600px
.MuiPaper-root.MuiDialog-paper {
  max-width: 1400px !important;
  min-width: 300px !important;
  overflow-x: hidden !important;
}

// dashboard no data box css
.inf-scroll-no-data-div {
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: none !important;
  height: 250px;
}
.inf-scroll-no-data-div__paper {
  width: 100%;
  box-shadow: none !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.warningMessage {
  text-align: center;
  background: red;
  color: #fff;
  padding: 10px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

.stencil-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  padding: 15px;
  width: 135px;
  p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
    font-weight: 500;
  }
  * {
    pointer-events: none;
  }
  .stencil-icon {
    position: absolute;
    transform: translateX(-50%);

    svg {
      width: 25px;
    }
  }
}
