.sortableItem {
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  width: 100%;
}

.DragHandle {
  width: 20px;
  cursor: var(--cursor, pointer);
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  margin-right: 5px;
}

.DragHandle:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.DragHandle:focus-visible {
  box-shadow: 0 0px 0px 2px #4c9ffe;
}